.addteam input[type="text"] , .editteam input[type="text"]{
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
    /* box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px; */
}

.addteam .fields ,.editteam .fields{
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 200;
}
.addteam .teamability,.editteam .teamability{
    line-height: 2;
    text-align: left;
    margin-bottom: 13px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 200;
    margin-right: 20px;

}
.addteam input[type="checkbox"],.editteam input[type="checkbox"]{
width: 20px;
height: 18px;
}
.addteam button[type="submit"],
.addteam input[type="submit"],.addteam .saveteam {
  background: #1f576c;
  color: white;
  text-transform: uppercase;
  border: none;
  margin: 50px 0 20px 0;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  min-height: 36px;
}

.addteam button[type="submit"]:hover,
.addteam input[type="submit"]:hover,.addteam .saveteam:hover {
    background: #b6d9e6;
    color: #1f576c;
}

.addteam button[type="submit"]:active,
.addteam input[type="button"]:active,
.addteam input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.addteam .error ,.teamability .error{
  font-size: 14px;
  color: red;
}
.editteam .form-buttons, .addteam .form-buttons{
  text-align: right;
}

.editteam button[type="submit"],
.editteam input[type="submit"] ,.editteam .saveteam{
  background: #1f576c;
  color: white;
  text-transform: uppercase;
  border: none;
  margin: 50px 0 20px 0;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  min-height: 36px;
}
.form-buttons .saveuser{
  background: #1f576c;
  color: white;
  text-transform: uppercase;
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  min-height: 36px;
  margin: 0 !important;
}
.editteam button[type="submit"]:hover,
.editteam input[type="submit"]:hover ,.editteam .saveteam:hover,.form-buttons .saveuser:hover{
    background: #b6d9e6;
    color: #1f576c;
}
.addteam .cancel,.editteam .cancel ,.form-buttons .cancel{
  background: #f1f0f0;
  color: #393939;
  text-transform: uppercase;
  border: none;
  margin: 20px 0 20px 0;
  padding: 15px;
  font-size: 14px;
  border-radius: 5px;
  min-height: 36px;
  margin-right: 15px;
}
label.userrole {
  margin-left: 0;
}