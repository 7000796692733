.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Dashboard-Icon {
  color: orange;
  size: huge;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiButtonBase-root.MuiTablePagination-menuItem {
  display: flex;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
  display: flex;
  justify-content: flex-start;
}

footer {
  /* background-color: #eeeeee; */
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 0.1px;
  size: 1px;
}

.user-activity .chart-wrapper {
  width: 300px;
  height: 300px;
  margin: 20px;
}

.analytics-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.user-activity .chart-section {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.user-activity .user-activity-section,
.user-activity .file-download-section {
  flex: 1;
  margin: 0 20px 20px 20px;
}

.user-activity .chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-activity .chart-description {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.user-activity .filter-section {
  display: flex;
  align-items: center; 
  padding: 20px;
}

.user-activity .date-picker {
  display: flex;
  align-items: center; 
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}
.user-activity .date-picker input {
  font-family: Arial, Helvetica, sans-serif;
}
.user-activity .date-picker label {
  margin-right: 50px;
}

.contact-us-form .form-group {
  margin-bottom: 20px;
}

.contact-us-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #335f70;
}

.contact-us-form input,
.contact-us-form select,
.contact-us-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-us-form #contactSubmit {
  background-color:#335f70;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-us-form button:hover {
  background-color: #0056b3;
}

.contact-us-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  box-shadow: 0px 0px 12px gainsboro;
}


.contact-us-page h1 {
  color: #335f70;
  text-align: center;
}

.contact-us-page p {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}